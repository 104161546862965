import React, {useState, useEffect} from "react"

import Layout from '../../components/layout';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import FreeTrial from "../../components/free-trial";
import SEO from "../../components/seo";

import LeftFeature from '../../components/left-feature';
import FeaturePararaph from '../../components/feature-paragraph'

import calculatorSrc from '../../images/calculator.svg';
import multiDeviceSrc from '../../images/multi-device.png';


const DecimalToHoursCalculator = () => {

	let [decimalTime, setDecimalTime] = useState(null);

	let [hours,setHours] = useState(null);
	let [minutes,setMinutes] = useState(null);


	let [clipboardBtnText, setClipboardBtnText] = useState(null);

	let result = `${hours} ${hours > 1 ? 'hours': 'hour'} ${minutes} ${minutes > 1 ? 'minutes': 'minute'}`;

	useEffect(() => {
		let floatDecimalTime = parseFloat(decimalTime).toFixed(2);
		if(decimalTime != null && !isNaN(floatDecimalTime)){

			let hoursElement = parseInt(decimalTime, 10);
			setHours(hoursElement)

			let remainingDecimal = floatDecimalTime - hoursElement;
			let remainingMinutes = Math.round(remainingDecimal * 60)
			setMinutes(remainingMinutes);
		}else{
			setHours(null);
			setMinutes(null);
		}
	}, [decimalTime])

	return (
		<div className="mt-8 grid grid-cols-2">
			<div className="col-span-2">
	          <label htmlFor="decimal_time" class="block text-sm font-medium leading-5 text-gray-600">Decimal Hours Worked Time</label>
	          <div className="mt-1 grid-cols-2 relative">
	            <input id="decimal_time" min="0" value={decimalTime} onChange={(e) => {
	            	if(e.target.value === ''){
	            		setDecimalTime(null);
	            	}else{
		            	const val = Math.max(0, parseFloat(e.target.value, 10));
		            	setDecimalTime(val)
		            }
	            }} type="number" placeholder="Enter decimal time" className="form-input rounded-md border-highlight-400 border-2 py-3 px-4 block w-full focus:outline-none transition ease-in-out duration-150" />
	          </div>
	        </div>
	        {hours != null && minutes != null && 
	        	
        		<div className="col-span-1 lg:col-span-2 text-center">
		        	<h2 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">{decimalTime} hours in decimal = <span className="text-highlight-400">{result}</span></h2>
		        	<button type="button" onClick={() => {
		        		navigator.clipboard.writeText(result);
		        		setClipboardBtnText('Copied')
		        		setTimeout(() => {
		        			setClipboardBtnText(null);
		        		}, 1000)
		        	}} className="inline-flex items-center justify-center px-6 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-highlight-600 focus:outline-none focus:outline-none transition ease-in-out duration-150">
		              {clipboardBtnText == null && <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" /></svg>} {clipboardBtnText != null ? clipboardBtnText : ' Copy to clipboard'}
		            </button>
		        </div>
		    }
	    </div>
	)
}

const DecimalToHoursMinutesCalculator = () => (
  <Layout>
    <SEO 
    	title="Convert Decimal Time to Hours and Minutes Timesheet Calculator"
    	description="Convert time worked in decimal to hours and minutes with our free calculator. Enter your decimal hours worked and get the answer instantly with our calculator."
    	 />
    <Navbar />
    <div className="bg-gray-50">
		<div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
		  <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
		     <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
			    <div className="mb-8 lg:mb-0 flex flex-col">
			    	<div className="flex justify-center">
			    		<img className="w-1/4" src={calculatorSrc} alt="Calculator" />
			    	</div>
					<DecimalToHoursCalculator />
			    </div>
			    <div className="lg:col-start-2 lg:pl-8">
			      <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
			        <p className="leading-6 text-highlight-600 font-semibold tracking-tight uppercase">Decimal Time to Hours and Minutes Calculator</p>
			        <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">Convert decimal time to hours and minutes with our free calculator.</h1>
			        <div className="prose text-gray-500">
			          <p>A common task with timesheets is figuring out the hours and minutes equivalent for decimal time an employee worked.</p>
					  <p>For example, <strong>30.5 hours worked</strong> is <strong>30 hours and 30 minutes </strong> in hours and minutes format but it can get more complex. If you're a math whizz, you probably know what <strong>30.70 hours</strong> is in hours and minutes.</p>
			          <p>However if you are like us (or need your morning cup of coffee!), we use computers to do it for us. So we thought, let's make a <strong>free</strong> calculator to help everyone else as well!</p>
			        </div>
			      </div>
			    </div>
			  </div>
			  <LeftFeature 
			    	sectionTitle="Stop doing manual timesheets forever" 
			    	title="Move to Cloud-based Time and Attendance Software" 
			    	body={
			    		<div>
					        <FeaturePararaph>
					          The above calculation is just one of many pains in managing employee time and attendance.
					        </FeaturePararaph>
					        <FeaturePararaph>
					        	If you had a few minutes, we'd love for you to check out TimeKeeper. Our simple solution automates the pain of employee timesheets, saving you time and money so you can focus on other tasks or just relax!
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={multiDeviceSrc}
			    	imgAlt="Move to Cloud Based Time and Attendance Software"
			    />
		  </div>
		</div>
	  	
	</div>
	<FreeTrial showDemoBooking={true} part1={<span>Ready to make the first step to <span className="text-highlight-600">automated</span> <br /> employee timesheets?</span>} />
	<Footer/>
  </Layout>
)

export default DecimalToHoursMinutesCalculator
